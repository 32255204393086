import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import cookeis from 'js-cookie';
import { tabletAbove } from '@/utils/helpers';

const Header = () => {
  const handleLogout = () => {
    cookeis.remove('access_token');
    location.reload();
  };

  return (
    <Layout.Header>
      <img
        src="//cdn.54zfj.cn/dist/images/public/new-logo-cn.png"
        css={css`
          padding: 12px 32px 12px 0;
          height: 60px;
          float: left;
        `}
      />
      <Menu
        mode="horizontal"
        theme="dark"
        defaultSelectedKeys={[
          window.location.pathname.split('/')?.[1] || 'dashboard',
        ]}
        css={css`
          position: relative;
        `}
      >
        <Menu.Item key="dashboard">
          <Link to="/dashboard">Dashboard</Link>
        </Menu.Item>
        <Menu.Item key="properties">
          <Link to="/properties">Properties</Link>
        </Menu.Item>
        <Menu.Item key="reports">
          <Link to="/reports">Reports</Link>
        </Menu.Item>
        <Menu.SubMenu
          key="settings"
          title="Settings"
          css={css`
            ${tabletAbove} {
              position: absolute !important;
              right: 0;
            }
          `}
        >
          <Menu.Item key="reset-password">
            <Link to="/reset-password">Reset password</Link>
          </Menu.Item>
          <Menu.Item key="logout" onClick={handleLogout}>
            Log out
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    </Layout.Header>
  );
};

export default Header;
