import { IMutation, IMutationUpdatePasswordArgs } from '@/types/graphql';
import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import cookies from 'js-cookie';
import { Button, Form, Input, message } from 'antd';
import { RESET_PASSWORD } from './gql';

const ResetPassword = () => {
  const [updateRoom, { loading }] = useMutation<
    IMutation,
    IMutationUpdatePasswordArgs
  >(RESET_PASSWORD);

  const onFinish = async (values: any) => {
    const res = await updateRoom({
      variables: {
        input: {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        },
      },
    });

    if (res?.data?.updatePassword?.success) {
      message.success('Reset successfully, Redirecting to login');
      setTimeout(() => {
        cookies.remove('access_token');
        location.reload();
      }, 2000);
    }
  };

  return (
    <Form
      name="basic"
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
      css={css`
        max-width: 400px;
        margin: 0 auto;
      `}
    >
      <Form.Item
        label="Original Password"
        name="oldPassword"
        rules={[
          { required: true, message: 'Please input your original password!' },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label="New Password"
        name="newPassword"
        rules={[{ required: true, message: 'Please input your newPassword!' }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirmPassword"
        label="Confirm Password"
        dependencies={['newPassword']}
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error('The two passwords that you entered do not match!')
              );
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ResetPassword;
