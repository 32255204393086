import { Fragment, useState } from 'react';
import {
  Table,
  Badge,
  Form,
  InputNumber,
  Button,
  Space,
  notification,
  Tooltip,
} from 'antd';
import moment from 'moment';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import { formatMoney, getZfjHost } from '@/utils/helpers';
import {
  IBid,
  IQuery,
  IMutation,
  IMutationCreateBidArgs,
  IMutationUpdateBidArgs,
  IMutationActiveBidArgs,
  IMutationPauseBidArgs,
} from '@/types/graphql';
import { GET_BIDS, CREATE_BID, UPDATE_BID, ACTIVE_BID, STOP_BID } from '../gql';
import { css } from '@emotion/react';

const Bids = () => {
  const [form] = Form.useForm();
  const [editRecord, setEditRecord] = useState<IBid>(null);

  const { data, loading, refetch } = useQuery<IQuery>(GET_BIDS);
  const [createBid, { loading: createLoading }] = useMutation<
    IMutation,
    IMutationCreateBidArgs
  >(CREATE_BID);

  const [updateBid, { loading: updateLoading }] = useMutation<
    IMutation,
    IMutationUpdateBidArgs
  >(UPDATE_BID);

  const [activeBid, { loading: activeLoading }] = useMutation<
    IMutation,
    IMutationActiveBidArgs
  >(ACTIVE_BID);

  const [stopBid, { loading: stopLoading }] = useMutation<
    IMutation,
    IMutationPauseBidArgs
  >(STOP_BID);

  const handleFormSubmit = async (values: IBid) => {
    if (editRecord.bidId) {
      const result = await updateBid({
        variables: {
          input: {
            ...values,
            id: editRecord.bidId,
          },
        },
      });

      if (result?.data?.updateBid?.success) {
        setEditRecord(null);
        refetch();
      }
      return;
    }

    const result = await createBid({
      variables: {
        input: {
          ...values,
          propertyId: editRecord.propertyId,
          currency: 'GBP',
        },
      },
    });

    if (result?.data?.createBid?.success) {
      setEditRecord(null);
      refetch();
    }
  };

  const handleEdit = (record: IBid) => {
    setEditRecord(record);
    form.setFieldsValue(record);
  };

  const handleActiveBid = async (record: IBid) => {
    const result = await activeBid({
      variables: {
        id: record.bidId,
      },
    });

    if (result?.data?.activeBid?.success) {
      return refetch();
    }

    notification.error({
      message: 'Active bid failed',
      description: `Reason: ${result?.data?.activeBid?.reason}`,
    });
  };

  const handleStopBid = async (record: IBid) => {
    const result = await stopBid({
      variables: {
        id: record.bidId,
      },
    });

    if (result?.data?.stopBid?.success) {
      refetch();
    }
  };

  return (
    <Form form={form} onFinish={handleFormSubmit}>
      <Table
        columns={[
          {
            title: 'Property',
            dataIndex: 'propertyName',
            key: 'propertyName',
            render: (text: string, record: IBid) => (
              <a
                href={`${getZfjHost()}/${record.country}/flat/${record.city}/${
                  record.propertyId
                }`}
                target="_blank"
                rel="noreferrer"
              >
                {text}
              </a>
            ),
          },
          {
            title: 'Location',
            dataIndex: 'city',
            key: 'city',
            sorter: (a: IBid, b: IBid) => a.city.localeCompare(b.city),
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            filters: [
              { text: 'Active', value: 'ACTIVE' },
              { text: 'Suspend', value: 'SUSPEND' },
              { text: 'Stopped', value: 'STOPPED' },
            ],
            onFilter: (value, record: IBid) => {
              if (value === 'ACTIVE' || value === 'SUSPEND') {
                return record.status === value;
              }
              return value
                ? ['ACTIVE', 'SUSPEND'].indexOf(record.status) === -1
                : true;
            },
            render: (status: string) => {
              if (status === 'ACTIVE') {
                return <Badge status="success" text="Active" />;
              }

              if (status === 'SUSPEND') {
                return <Badge status="warning" text="Suspend" />;
              }

              return <Badge status="error" text="Stopped" />;
            },
          },
          {
            title: (
              <>
                Budget
                <Tooltip
                  placement="rightTop"
                  css={css`
                    margin-left: 8px;
                  `}
                  title="please set daily cap"
                >
                  <QuestionCircleOutlined
                    css={css`
                      cursor: pointer;
                    `}
                  />
                </Tooltip>
              </>
            ),
            dataIndex: 'budget',
            key: 'budget',
            width: 180,
            render: (budget: number, record: IBid) =>
              editRecord?.propertyId === record?.propertyId ? (
                <Form.Item
                  name="budget"
                  rules={[
                    { required: true },
                    { type: 'number', min: 2, message: 'No less than £2' },
                  ]}
                  css={css`
                    margin-bottom: 0;
                  `}
                >
                  <InputNumber prefix="£" addonAfter=" / day" precision={2} />
                </Form.Item>
              ) : budget ? (
                `${formatMoney(budget, record.currency)} / day`
              ) : (
                '-'
              ),
          },
          {
            title: (
              <>
                Bid
                <Tooltip
                  placement="rightTop"
                  css={css`
                    margin-left: 8px;
                  `}
                  title="please set your bid price. Higher bid ad will be shown in the 1st place of property search result page"
                >
                  <QuestionCircleOutlined
                    css={css`
                      cursor: pointer;
                    `}
                  />
                </Tooltip>
              </>
            ),
            dataIndex: 'cpc',
            key: 'cpc',
            width: 150,
            render: (cpc: number, record: IBid) =>
              editRecord?.propertyId === record?.propertyId ? (
                <Form.Item
                  name="cpc"
                  rules={[
                    { required: true },
                    { type: 'number', min: 2, message: 'No less than £2' },
                  ]}
                  css={css`
                    margin-bottom: 0;
                  `}
                >
                  <InputNumber prefix="£" precision={2} />
                </Form.Item>
              ) : (
                formatMoney(cpc, record.currency) || '-'
              ),
          },
          {
            title: 'totalCost',
            dataIndex: 'totalCost',
            key: 'totalCost',
            sorter: (a: IBid, b: IBid) => a.totalCost - b.totalCost,
            render: (totalCost: number, record: IBid) =>
              formatMoney(totalCost, record.currency) || '-',
          },
          {
            title: 'Last Updated',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (date: string) =>
              date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '-',
          },
          {
            title: 'Actions',
            key: 'actions',
            render: (_: any, record: IBid) => {
              if (editRecord?.propertyId === record?.propertyId) {
                return (
                  <Space size={0}>
                    <Form.Item
                      css={css`
                        margin-bottom: 0;
                      `}
                    >
                      <Button
                        htmlType="submit"
                        type="link"
                        size="small"
                        disabled={updateLoading || createLoading}
                      >
                        Update
                      </Button>
                    </Form.Item>
                    <Button
                      onClick={() => handleEdit(null)}
                      type="link"
                      size="small"
                    >
                      Cancel
                    </Button>
                  </Space>
                );
              }

              return (
                <>
                  {record?.budget && record?.cpc && record.status !== 'ACTIVE' && (
                    <Button
                      onClick={() => handleActiveBid(record)}
                      type="link"
                      size="small"
                      disabled={activeLoading}
                    >
                      Active
                    </Button>
                  )}

                  {record.status === 'ACTIVE' && (
                    <Button
                      onClick={() => handleStopBid(record)}
                      type="link"
                      size="small"
                      disabled={stopLoading}
                    >
                      Stop
                    </Button>
                  )}
                  <Button
                    onClick={() => handleEdit(record)}
                    type="link"
                    size="small"
                  >
                    Edit
                  </Button>
                </>
              );
            },
          },
        ]}
        dataSource={data?.bids}
        loading={loading}
        rowKey="propertyId"
        pagination={false}
      />
    </Form>
  );
};

export default Bids;
