import { IQuery } from '@/types/graphql';
import { formatMoney } from '@/utils/helpers';
import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { Card, Statistic, Row, Col, Button, Tooltip, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { GET_LANDLORD } from '../gql';
import { ReactNode } from 'react';

type StatisticItemType = {
  title: string;
  tip: ReactNode;
  value: string | number;
  loading: boolean;
};

const StatisticItem = ({ title, tip, value, loading }: StatisticItemType) => (
  <Statistic
    title={
      <>
        {title}
        <Tooltip
          placement="rightTop"
          css={css`
            margin-left: 8px;
          `}
          title={tip}
        >
          <QuestionCircleOutlined
            css={css`
              cursor: pointer;
            `}
          />
        </Tooltip>
      </>
    }
    value={value}
    loading={loading}
  />
);

const Overview = () => {
  const { data, loading } = useQuery<IQuery>(GET_LANDLORD);

  return (
    <div
      css={css`
        margin-bottom: 32px;
      `}
    >
      <Typography.Title level={2}>Hi {data?.landlord?.name},</Typography.Title>
      <Row gutter={16}>
        <Col span={6}>
          <Card
            css={css`
              height: 100%;
            `}
          >
            <StatisticItem
              title="Impressions"
              tip={
                <>
                  <strong>Impression</strong> is counted each time your property
                  page has been clicked by users
                </>
              }
              loading={loading}
              value={data?.landlord?.overview?.impressions}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card
            css={css`
              height: 100%;
            `}
          >
            <StatisticItem
              title="Clicks"
              tip={
                <>
                  <strong>Click</strong> is counted each time your website
                  visitors have been referred from our site
                </>
              }
              loading={loading}
              value={data?.landlord?.overview?.clicks}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card
            css={css`
              height: 100%;
            `}
          >
            <StatisticItem
              title="CTR"
              tip={
                <>
                  <strong>CTR</strong> is Click-Through-Rate
                </>
              }
              loading={loading}
              value={`${data?.landlord?.overview?.CTR}%`}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card
            css={css`
              height: 100%;
            `}
          >
            <Statistic
              title="Account Balance"
              value={formatMoney(
                data?.landlord?.balance || 0,
                data?.landlord?.balanceCurrency
              )}
              loading={loading}
            />
            <Button style={{ marginTop: 16 }} type="primary">
              Recharge
            </Button>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Overview;
