import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  NormalizedCacheObject,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import Cookie from 'js-cookie';

const httpLink = new HttpLink({ uri: '/graphql/landlord-ads' });

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: Cookie.get('access_token'),
    },
  }));

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (message === 'UNAUTHENTICATED' || message === 'FORBIDDEN') {
        if (window.location.hostname.startsWith('landlord')) {
          return window.location.assign('https://www.54zfj.cn/admin/login');
        }
        if (window.location.hostname.startsWith('stage-landlord')) {
          return window.location.assign('https://stage.54zfj.cn/admin/login');
        }
        // DO NOTHING
        // return window.location.assign('https://stage.54zfj.cn/admin/login');
      }
    });
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
    mutate: {
      fetchPolicy: 'no-cache',
    },
  },

  link: from([errorLink, authMiddleware, httpLink]),
});

export default client;
