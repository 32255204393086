import { gql } from '@apollo/client';

export const GET_REPORTS = gql`
  query GetReports($limit: Int!, $offset: Int!) {
    reports(limit: $limit, offset: $offset) {
      date
      propertyId
      property {
        name
        city
      }
      impressions
      clicks
      costs
      topPositionCount
    }
  }
`;
