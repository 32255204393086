import { Layout } from 'antd';
import { css } from '@emotion/react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Header from '@/components/Header';
import Auth from '@/components/Auth';
import Dashboard from '@/pages/Dashboard';
import Properties from '@/pages/Properties';
import Reports from '@/pages/Reports';
import { tabletAbove } from '@/utils/helpers';
import client from './apolloClient';
import ResetPassword from './pages/ResetPassword';

const App = () => (
  <ApolloProvider client={client}>
    <BrowserRouter>
      <Layout>
        <Header />
        <Layout.Content
          css={css`
            padding: 16px;

            ${tabletAbove} {
              padding: 24px 50px;
            }
          `}
        >
          <Switch>
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/properties" component={Properties} />
            <Route path="/reports" component={Reports} />
            <Route path="/auth" component={Auth} />
            <Route path="/reset-password" component={ResetPassword} />
            <Redirect to="/dashboard" />
          </Switch>
        </Layout.Content>
      </Layout>
    </BrowserRouter>
  </ApolloProvider>
);

export default App;
