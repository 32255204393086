import {
  Table,
  Space,
  Form,
  DatePicker,
  InputNumber,
  Button,
  Select,
} from 'antd';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import {
  IQuery,
  IRoom,
  IMutation,
  IMutationUpdateRoomArgs,
} from '@/types/graphql';
import { GET_ROOMS, UPDATE_ROOM } from './gql';
import { css } from '@emotion/react';

type IRoomTypesProps = {
  propertyId: number;
};

const RoomTypes = ({ propertyId }: IRoomTypesProps) => {
  const [form] = Form.useForm();
  const [editRecord, setEditRecord] = useState<IRoom>(null);
  const { data, loading, refetch } = useQuery<IQuery>(GET_ROOMS, {
    variables: {
      propertyId,
    },
  });

  const [updateRoom, { loading: updateRoomLoading }] = useMutation<
    IMutation,
    IMutationUpdateRoomArgs
  >(UPDATE_ROOM);

  const handleFormSubmit = async (values: IRoom) => {
    const result = await updateRoom({
      variables: {
        input: {
          ...values,
          startDate: moment(values.startDate).format('YYYY-MM-DD'),
          id: editRecord.id,
        },
      },
    });

    if (result?.data?.updateRoom?.success) {
      setEditRecord(null);
      refetch();
    }
  };

  const handleEdit = (record: IRoom) => {
    setEditRecord(record);
    form.setFieldsValue({
      ...record,
      startDate: moment(record?.startDate),
    });
  };

  return (
    <Form form={form} onFinish={handleFormSubmit}>
      <Table
        size="small"
        scroll={{ x: 800 }}
        columns={[
          {
            title: 'Room Name',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Check-in Date',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (startDate: string, record: IRoom) =>
              editRecord?.id === record?.id ? (
                <Form.Item
                  name="startDate"
                  rules={[{ required: true }]}
                  css={css`
                    margin-bottom: 0;
                  `}
                >
                  <DatePicker />
                </Form.Item>
              ) : (
                moment(startDate).format('YYYY-MM-DD')
              ),
          },
          {
            title: 'Stays',
            dataIndex: 'minDuration',
            key: 'minDuration',
            render: (minDuration: number, record: IRoom) =>
              editRecord?.id === record?.id ? (
                <Form.Item
                  name="minDuration"
                  rules={[{ required: true }]}
                  css={css`
                    margin-bottom: 0;
                  `}
                >
                  <InputNumber />
                </Form.Item>
              ) : (
                minDuration
              ),
          },
          {
            title: 'Check-out Date',
            dataIndex: 'checkOutDate',
            key: 'checkOutDate',
            render: (_: any, record: IRoom) => {
              if (editRecord?.id === record?.id) {
                return (
                  <Form.Item
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.startDate !== currentValues.startDate ||
                      prevValues.minDuration !== currentValues.minDuration
                    }
                    css={css`
                      margin-bottom: 0;
                    `}
                  >
                    {({ getFieldValue }) => {
                      const startDate = getFieldValue('startDate');
                      const minDuration = getFieldValue('minDuration');
                      return moment(startDate)
                        .add(Number(minDuration), 'days')
                        .format('YYYY-MM-DD');
                    }}
                  </Form.Item>
                );
              }
              return moment(record.startDate)
                .add(Number(record.minDuration), 'days')
                .format('YYYY-MM-DD');
            },
          },
          {
            title: 'Discount Price',
            dataIndex: 'originalPrice',
            key: 'originalPrice',
            render: (originalPrice: number, record: IRoom) =>
              editRecord?.id === record?.id ? (
                <Form.Item
                  name="originalPrice"
                  css={css`
                    margin-bottom: 0;
                  `}
                >
                  <InputNumber />
                </Form.Item>
              ) : (
                originalPrice
              ),
          },
          {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (price: number, record: IRoom) =>
              editRecord?.id === record?.id ? (
                <Form.Item
                  name="price"
                  css={css`
                    margin-bottom: 0;
                  `}
                  hasFeedback
                  rules={[
                    { required: true },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const originalPrice = getFieldValue('originalPrice');
                        if (
                          !value ||
                          !originalPrice ||
                          originalPrice >= value
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error());
                      },
                    }),
                  ]}
                >
                  <InputNumber />
                </Form.Item>
              ) : (
                price
              ),
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: string, record: IRoom) =>
              editRecord?.id === record?.id ? (
                <Form.Item
                  name="status"
                  rules={[{ required: true }]}
                  css={css`
                    margin-bottom: 0;
                  `}
                >
                  <Select>
                    <Select.Option value="PUBLISHED">PUBLISHED</Select.Option>
                    <Select.Option value="UNPUBLISHED">
                      UNPUBLISHED
                    </Select.Option>
                  </Select>
                </Form.Item>
              ) : (
                status
              ),
          },
          {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: 125,
            fixed: 'right',
            render: (_: any, record: IRoom) =>
              editRecord?.id === record?.id ? (
                <Space size={0}>
                  <Form.Item
                    css={css`
                      margin-bottom: 0;
                    `}
                  >
                    <Button
                      htmlType="submit"
                      type="link"
                      size="small"
                      loading={updateRoomLoading}
                    >
                      Update
                    </Button>
                  </Form.Item>
                  <Button
                    onClick={() => handleEdit(null)}
                    type="link"
                    size="small"
                  >
                    Cancel
                  </Button>
                </Space>
              ) : (
                <Button
                  onClick={() => handleEdit(record)}
                  type="link"
                  size="small"
                >
                  Edit
                </Button>
              ),
          },
        ]}
        dataSource={data?.rooms}
        loading={loading}
        rowKey="id"
      />
    </Form>
  );
};

export default RoomTypes;
