export const formatMoney = (number: number, currency: string) => {
  if (!currency) {
    return '';
  }

  const mapping: { [key: string]: string } = {
    CNY: 'zh-CN',
    GBP: 'en-GB',
    USD: 'en-US',
  };

  return new Intl.NumberFormat(mapping?.[currency] ?? 'zh-CN', {
    style: 'currency',
    currency,
  }).format(number);
};

export const breakpoints = [768, 1024];
export const mobileOnly = `@media (max-width: ${breakpoints[0]}px)`;
export const tabletAbove = `@media (min-width: ${breakpoints[0]}px)`;
export const desktopAbove = `@media (min-width: ${breakpoints[1]}px)`;

export const getZfjHost = () => {
  if (window.location.hostname.startsWith('landlord')) {
    return 'https://www.54zfj.cn';
  }
  if (window.location.hostname.startsWith('stage-landlord')) {
    return 'https://stage.54zfj.cn';
  }

  return '';
};
