import Bids from './Bids';
import Overview from './Overview';

const Dashboard = () => {
  return (
    <div>
      <Overview />
      <Bids />
    </div>
  );
};

export default Dashboard;
