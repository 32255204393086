import { IQuery, IReport } from '@/types/graphql';
import { formatMoney } from '@/utils/helpers';
import { useLazyQuery } from '@apollo/client';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Table, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { GET_REPORTS } from './gql';
import { css } from '@emotion/react';

const Reports = () => {
  const [getReports, { data, loading }] = useLazyQuery<IQuery>(GET_REPORTS);

  useEffect(() => {
    getReports({
      variables: {
        limit: 50,
        offset: 0,
      },
    });
  }, []);

  const handleChange = (params: any) => {
    console.log(params);
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date: string) => moment(Number(date)).format('YYYY-MM-DD'),
    },
    {
      title: 'Property',
      dataIndex: 'propertyName',
      key: 'propertyName',
      render: (_: any, record: IReport) => record.property?.name,
    },
    {
      title: 'Location',
      dataIndex: 'propertyCity',
      key: 'propertyCity',
      render: (_: any, record: IReport) => record.property?.city,
    },
    {
      title: 'Impressions',
      dataIndex: 'impressions',
      key: 'impressions',
    },
    {
      title: 'Clicks',
      dataIndex: 'clicks',
      key: 'clicks',
    },
    {
      title: 'CTR',
      dataIndex: 'CTR',
      key: 'CTR',
      render: (_: any, record: IReport) =>
        `${Math.floor((record.clicks / record.impressions) * 100)}%`,
    },
    {
      title: 'Total Costs',
      dataIndex: 'costs',
      key: 'costs',
      render: (costs: number) => formatMoney(costs, 'GBP'),
    },
    {
      title: (
        <>
          1st Place
          <Tooltip
            placement="rightTop"
            css={css`
              margin-left: 8px;
            `}
            title={
              <>
                <strong>1st place</strong> is your property has been shown in
                the 1st place ofproperty search result page
              </>
            }
          >
            <QuestionCircleOutlined
              css={css`
                cursor: pointer;
              `}
            />
          </Tooltip>
        </>
      ),
      dataIndex: 'topPositionCount',
      key: 'topPositionCount',
      render: (topPositionCount: number, record: IReport) =>
        `${Math.floor((topPositionCount / record.impressions) * 100)}%`,
    },
  ];

  return (
    <div>
      <Table
        tableLayout="fixed"
        columns={columns}
        dataSource={data?.reports}
        onChange={handleChange}
        loading={loading}
      />
    </div>
  );
};

export default Reports;
