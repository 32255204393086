import { gql } from '@apollo/client';

export const GET_LANDLORD = gql`
  query getLandlord {
    landlord {
      name
      balance
      balanceCurrency
      overview {
        impressions
        clicks
        CTR
      }
    }
  }
`;

export const GET_BIDS = gql`
  query GetBids {
    bids {
      bidId
      landlordId
      propertyId
      propertyName
      country
      city
      budget
      cpc
      currency
      totalCost
      status
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_BID = gql`
  mutation CreateBid($input: CreateBidInput!) {
    createBid(input: $input) {
      success
    }
  }
`;

export const UPDATE_BID = gql`
  mutation UpdateBid($input: UpdateBidInput!) {
    updateBid(input: $input) {
      success
    }
  }
`;

export const ACTIVE_BID = gql`
  mutation ActiveBid($id: Int!) {
    activeBid(id: $id) {
      success
      reason
    }
  }
`;

export const PAUSE_BID = gql`
  mutation PauseBid($id: Int!) {
    pauseBid(id: $id) {
      success
    }
  }
`;

export const STOP_BID = gql`
  mutation StopBid($id: Int!) {
    stopBid(id: $id) {
      success
    }
  }
`;
