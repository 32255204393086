import { gql } from '@apollo/client';

export const GET_PROPERTIES = gql`
  query GetProperties {
    properties {
      id
      name
      country
      city
      status
    }
  }
`;

export const GET_ROOMS = gql`
  query GetRooms($propertyId: Int!) {
    rooms(propertyId: $propertyId) {
      id
      name
      startDate
      minDuration
      originalPrice
      price
      status
    }
  }
`;

export const UPDATE_ROOM = gql`
  mutation UpdateRoom($input: UpdateRoomInput!) {
    updateRoom(input: $input) {
      success
    }
  }
`;
