import { Redirect, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import cookies from 'js-cookie';

const Auth = () => {
  const location = useLocation();
  const history = useHistory();
  const { token } = queryString.parse(location.search);

  if (!token) {
    history.goBack();
  }

  cookies.set('access_token', decodeURIComponent(token as string), {
    expires: 1,
  });

  return <Redirect to="/dashboard" />;
};

export default Auth;
