import { Table } from 'antd';
import { useQuery } from '@apollo/client';
import RoomTypes from './roomTypes';
import { IQuery, IProperty } from '@/types/graphql';
import { getZfjHost } from '@/utils/helpers';
import { GET_PROPERTIES } from './gql';

const Properties = () => {
  const { data, loading } = useQuery<IQuery>(GET_PROPERTIES);

  const columns = [
    {
      title: 'Property',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: IProperty) => (
        <a
          href={`${getZfjHost()}/${record.country}/flat/${record.city}/${
            record.id
          }`}
          target="_blank"
          rel="noreferrer"
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'city',
      key: 'city',
      sorter: (a: IProperty, b: IProperty) => a.city.localeCompare(b.city),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  return (
    <div>
      <Table
        tableLayout="fixed"
        rowKey="id"
        columns={columns}
        dataSource={data?.properties?.filter(
          (property) => property.status === 'PUBLISHED'
        )}
        loading={loading}
        expandable={{
          expandedRowRender: (record: IProperty) => (
            <RoomTypes propertyId={record.id} />
          ),
        }}
      />
    </div>
  );
};

export default Properties;
